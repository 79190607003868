import Bugsnag from "@bugsnag/js";

export function BugsnagLogError(
  error: Error,
  metadata: Record<string, Record<string, unknown>> | undefined = undefined,
) {
  if (process.env.NODE_ENV === "production") {
    if (!process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
      console.error(error, metadata);

      return;
    }

    Bugsnag.createClient({
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
    }).notify(error, (event) => {
      if (metadata) {
        for (const key of Object.keys(metadata)) {
          event.addMetadata(key, metadata[key]);
        }
      }
    });
  }
}
