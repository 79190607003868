"use client";

import Error from "next/error";
import { useEffect } from "react";
import { BugsnagLogError } from "@/helpers/error-handler";

export default function GlobalError({ error }) {
  useEffect(() => {
    BugsnagLogError(error);
  }, [error]);

  return (
    <html>
      <body>
        <Error statusCode={500} />
      </body>
    </html>
  );
}
